<template>
<div>
    <div class="side-mb ">
        <ul class="nav-side-mb">
            <li>
                <div class="lv1">
                    <i class="el-icon-time"></i>
                    <span>常用功能</span>
                </div>
                <ul class="sub-nav">
                    <li :class="istrue==1?'current':''">
                        <a @click="catli(1)">
                            下载记录
                        </a>
                    </li>
                    <li :class="istrue==2?'current':''">
                        <a @click="catli(2)">
                            我的收藏
                        </a>
                    </li>
                    <li :class="istrue==3?'current':''">
                        <a @click="catli(3)">
                            我的消息
                        </a>
                    </li>
                    <li :class="istrue==4?'current':''">
                        <a @click="catli(4)">
                            卡密绑定
                        </a>
                    </li>
                </ul>
            </li>
            <li>
                <div class="lv1">
                    <i class="el-icon-s-tools"></i>
                    <span>个人设置</span>
                </div>
                <ul class="sub-nav">
                    <li :class="istrue==5?'current':''">
                        <a @click="catli(5)">
                            个人信息
                        </a>
                    </li>
                    <li :class="istrue==6?'current':''">
                        <a @click="catli(6)">
                            修改密码
                        </a>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
    <div class="main-mb">
        <router-view></router-view>
    </div>
    <BetterLogin :title="title" @childFn="parentFn"></BetterLogin>
</div>
</template>
<script>
export default {
    data() {
        return {
            istrue:1,
            headerFixed:0,
            title:''
        }
    },
    methods: {
        getapp(){
            var tokens = this.$store.state.token
            if(tokens==''){
                this.title = true
            }
        },
        catli(val){
            if(document.body.scrollWidth < 768){
                if(val==1){
                    this.$router.push({name:'Downloadlatest'})
                }else if(val==2){
                    this.$router.push({name:'Mycollection'})
                }else if(val==3){
                    this.$router.push({name:'Messagecenter'})
                }else if(val==4){
                    this.$router.push({name:'Conversioncode'})
                }else if(val==5){
                    this.$router.push({name:'Perstion'})
                }else if(val==6){
                    this.$router.push({name:'Chapassword'})
                }
                this.$emit('getMessage', 1);
            }else{
                if(val==1){
                    this.$router.push({name:'Downloadlatest'})
                }else if(val==2){
                    this.$router.push({name:'Mycollection'})
                }else if(val==3){
                    this.$router.push({name:'Messagecenter'})
                }else if(val==4){
                    this.$router.push({name:'Conversioncode'})
                }else if(val==5){
                    this.$router.push({name:'Perstion'})
                }else if(val==6){
                    this.$router.push({name:'Chapassword'})
                }
            }
        },
        parentFn(payload){
            this.title = payload
        },
        // 高亮
        getPath(){
            let currPath = this.$route;
            this.URL = currPath.name;
            if(this.URL == 'Downloadlatest'){
                this.istrue = 1
            }else if(this.URL == 'Mycollection'){
                this.istrue = 2
            }else if(this.URL == 'Messagecenter'){
                this.istrue = 3
            }else if( this.URL == 'Conversioncode'){
                this.istrue = 4
            }else if(this.URL == 'Perstion'){
                this.istrue = 5
            }else if(this.URL == 'Chapassword'){
                this.istrue = 6
            }
        }
    },
    watch: {
        $route(){
            this.getPath()
            this.getapp()
        }
    },
    mounted() {
        this.getapp()
        this.getPath()
    }
}
</script>
<style scoped>
@import "./../../styles/Page/Personalcenter.css";
</style>